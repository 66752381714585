import ContainerMain from "src/pages/common/ContainerMain"
import "./Template2Columns.scss"
import SectionTitle from "pages/common/SectionTitle"

export default function Template2Colums({
  title,
  alignType,
  dataTexts,
  image,
}) {
  const col1Html =
    alignType === "left" ? (
      <img alt="" className="uWXNYLrCyZ" src={image} loading="lazy" />
    ) : (
      <div className={`fcCHdHMrJE ${alignType}`}>
        {dataTexts?.map(content => {
          return (
            <div className="StjvRqrbev" key={content.id}>
              <div className="NIIdwOtIXI">
                <div className="IydGkLszEC">{content.title}</div>
                <div className="aQkWOzxVNg">{content.content}</div>
              </div>
            </div>
          )
        })}
      </div>
    )
  const col2Html =
    alignType === "right" ? (
      <img alt="" className="uWXNYLrCyZ" src={image} loading="lazy" />
    ) : (
      <div className={`fcCHdHMrJE ${alignType}`}>
        {dataTexts?.map(content => {
          return (
            <div className="StjvRqrbev" key={content.id}>
              <div className="NIIdwOtIXI">
                <div className="IydGkLszEC">{content.title}</div>
                <div className="aQkWOzxVNg">{content.content}</div>
              </div>
            </div>
          )
        })}
      </div>
    )
  return (
    <>
      <ContainerMain alignType={alignType}>
        <div className={`veojzQjoSI-home ${alignType}`}>
          <div className="cXjKWozutF">
            <div className={`rxsdilmsao ${alignType}`}>
              {title && (
                <div className="YYQohNmAHv mobile">
                  <SectionTitle title={title}></SectionTitle>
                </div>
              )}
              <div className="KbFTqrRWSD-home">{col1Html}</div>
              <div className={`iNzXenhHCj ${alignType}`}>
                <div className="kQFHTLqPss">
                  {title && (
                    <div className="YYQohNmAHv pc">
                      <SectionTitle title={title}></SectionTitle>
                    </div>
                  )}
                  {col2Html}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerMain>
    </>
  )
}
